import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {

  public apiUrl = environment.apiUrl;
  public mioAuth = 'https://auth.preprod.geopagos.com';
  public mioChekout = 'https://api-mio.preprod.geopagos.com';

  constructor(
    private http: HttpClient
  ) { }

  get(params: HttpParams): Observable<any> {
    const url = `${this.apiUrl}collection/list`
    return this.http.get(url, {
      params: params
    });
  }

  create(body: FormData): Observable<any> {
    let url = `${this.apiUrl}collection/create`;
    return this.http.post(url, body);
  }

  byBusiness(businessId: number): Observable<any> {
    const url = `${this.apiUrl}collection/list/by-business/${businessId}`
    return this.http.get(url);
  }

  getPdfVoucher(collectionId: number): Observable<any> {
    const url = `${this.apiUrl}collection/generate-pdf/${collectionId}`
    return this.http.get(url, { responseType: 'blob' });
  }

  reject(body: any): Observable<any> {
    const url = `${this.apiUrl}collection/reject`
    return this.http.post(url, body);
  }

  cancel(collectionId: number, data: any): Observable<any> {
    console.log(collectionId, data);
    const url = `${this.apiUrl}collection/cancel/${collectionId}`;
    return this.http.post(url, data);
  }

  generatePdf(projectId, statusId): Observable<any> {
    const url = `${this.apiUrl}collection/generate-collection-report/${statusId}/${projectId}`
    return this.http.get(url, { responseType: 'blob' });
  }
}
