<form [formGroup]="form" class="form-wrapper">
  <div class="row mx-0 md:mx-5">
    <div class="row mb-5">
      <div class="col-lg-6 col-md-12 mt-2">
        <span class="p-float-label">
          <input class="w-100" id="float-input" type="text" pInputText formControlName="receipt_number" [readonly]="true">
          <label for="float-input">Número de recibo *</label>
        </span>
      </div>
      <div class="col-md-12 col-lg-6 mt-2">
        <span class="p-float-label">
          <input class="w-100" id="pay-number" aria-describedby="pay-number-help" [ngClass]="{'ng-invalid ng-dirty': fiedIsInvalid('transaction_number')}" type="text" pInputText formControlName="transaction_number">
          <label for="pay-number">Número de transacción *</label>
        </span>
        <small *ngIf="fiedIsInvalid('transaction_number')" id="pay-number-help" class="p-error block">{{ getErrorMessage('transaction_numbe') }}</small>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-md-12 col-lg-6 mt-2 calendar-float">
        <span class="p-float-label">
          <p-calendar
            [maxDate]="todayDate"
            inputId="transaction_date"
            aria-describedby="pay-date-help"
            [ngClass]="{'ng-invalid ng-dirty':fiedIsInvalid('transaction_date')}"
            styleClass="w-100"
            formControlName="transaction_date"
            [showIcon]="true">
          </p-calendar>
          <label for="transaction_date" [class.active]="paymentDate">Fecha de transacción *</label>
        </span>
        <small *ngIf="fiedIsInvalid('transaction_date')" id="pay-date-help" class="p-error block">{{ getErrorMessage('transaction_date') }}</small>
      </div>
      <div class="col-md-12 col-lg-6 mt-2">
        <span class="p-float-label">
          <p-dropdown id="currency" placeholder="Seleccione una divisa" styleClass="w-100" aria-describedby="currency-help" [ngClass]="{'ng-invalid ng-dirty': fiedIsInvalid('currency_id')}" [options]="currencys" (onChange)="changeCurrency($event)" formControlName="currency_id" optionLabel="name" optionValue="id"></p-dropdown>
          <label for="currency">Divisa *</label>
        </span>
        <small *ngIf="fiedIsInvalid('currency_id')" id="currency-help" class="p-error block">{{ getErrorMessage('currency_id') }}</small>
      </div>
    </div>
    <div *ngIf="showCheckInfo" class="row mb-5">
      <div class="col-md-12 col-lg-6 mt-2">
        <span class="p-float-label">
          <p-dropdown id="bank" styleClass="w-100" [options]="banks" (onChange)="changeBank($event)" formControlName="origin_bank_id" optionLabel="name" optionValue="id"></p-dropdown>
          <label for="bank">Banco de origen *</label>
        </span>
      </div>
      <div class="col-md-12 col-lg-6 mt-2">
        <span class="p-float-label">
          <input class="w-100" id="pay-number" type="text" pInputText formControlName="check_number">
          <label for="pay-number">Nómero de Cheque *</label>
        </span>
      </div>
    </div>
    <div class="row mb-5">
      <div *ngIf="showRateInput" class="col-md-12 col-lg-4 mt-2">
        <span class="p-float-label">
          <input currencyMask [options]="{ prefix: '', thousands: ',', decimal: '.' }" class="w-100" id="rate" type="text" pInputText formControlName="rate">
          <label for="rate">Tasa *</label>
        </span>
      </div>
      <div class="col-md-12 mt-2" [ngClass]="showRateInput ? 'col-lg-4' : 'col-lg-6'">
        <span class="p-float-label">
          <input currencyMask [options]="{ prefix: '', thousands: ',', decimal: '.' }" aria-describedby="received_amount-help" [ngClass]="{'ng-invalid ng-dirty': fiedIsInvalid('received_amount')}" class="w-100" id="amount" type="text" pInputText formControlName="received_amount">
          <label for="amount">Monto recibido *</label>
        </span>
        <small *ngIf="fiedIsInvalid('received_amount')" id="received_amount-help" class="p-error block">{{ getErrorMessage('received_amount') }}</small>
      </div>
      <div class="col-md-12 mt-2" [ngClass]="showRateInput ? 'col-lg-4' : 'col-lg-6'">
        <span class="p-float-label">
          <input
            currencyMask
            [options]="{ prefix: '', thousands: ',', decimal: '.' }"
            aria-describedby="total_amount-help"
            [ngClass]="{'ng-invalid ng-dirty': fiedIsInvalid('total_amount')}"
            class="w-100"
            id="total"
            type="text"
            pInputText
            [readonly]="true"
            formControlName="total_amount">
          <label for="total">Total *</label>
        </span>
        <small *ngIf="fiedIsInvalid('total_amount')" id="total_amount-help" class="p-error block">{{ getErrorMessage('total_amount') }}</small>
      </div>
    </div>
  </div>

  <div class="form-footer mt-auto">
    <button pButton label="Guardar" (click)="approvePayment()" class="p-button-raised p-button-secondary mr-1"></button>
    <button type="button" pButton (click)="closeDialog()" label="Cancelar" class="p-button-raised p-button-text"></button>
  </div>
</form>

