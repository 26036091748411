import { Component, Input, OnDestroy, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Additional } from '@support/models/additional.model';
import { AdditionalsService } from '@support/services/additionals.service';
import { ModalConfirmComponent } from '@tools/components/modal-confirm/modal-confirm.component';
import { NotificationsService } from '@tools/services/notifications.service';
import { Subscription } from 'rxjs';
import { CreateAdditionalComponent } from './create-additional/create-additional.component';

@Component({
  selector: 'app-additional',
  templateUrl: './additional.component.html',
  styleUrls: ['./additional.component.scss']
})
export class AdditionalComponent implements OnInit, OnDestroy {

  public additionals: Additional[]=[];
  public isLoading = false;
  public displayedColumns: string[] = ['name', 'type', 'price', 'actions'];
  public dataSource: MatTableDataSource<Additional>;
  private subscriptions: Record<string, Subscription> = {};

  @Input() projectId:number;
  @Input() isDetail:boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _additional: AdditionalsService,
    private _snackBar: NotificationsService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.subscriptions['parkings'] = this._additional.updateAddditionalList.subscribe(
      (update: boolean) => {
        if (update) {
          this.getAdditional();
        }
      }
    );
    this.getAdditional();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('isDetail') && changes['isDetail'].currentValue !== null) {
      this.displayedColumns = (this.isDetail ? ['name'] : ['name', 'actions']);
    }
  }

  getAdditional():void{
    this._additional.get(1, this.projectId).subscribe(
      (res:any)=>{
        if (res && res.data) {
          this.additionals = res.data
          this.dataSource = new MatTableDataSource(this.additionals);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      },
      (err:any)=>{
        this._snackBar.openSnackBar('Error en consulta', 'OK');
        this.isLoading = false;
      }
    )
  }

  delete(id:number): void {
    this.dialog.open(ModalConfirmComponent, {
      data: {
        title: 'Eliminar Adicional',
        message: '¿Está seguro de que desea eliminar este Adicional? Perderá todos sus datos',
        btnConfirm: true,
        btnCancel: true,
        btnConfirmText:'Aceptar',
        btnCancelText: 'Cancelar'
      }
    })
      .afterClosed()
      .subscribe((confirm: Boolean) => {
        if (confirm) {
          this._additional.delete(id).subscribe(
            (res:any)=>{
              this._snackBar.openSnackBar('Adicional borrado exitosamente.', 'OK');
              this.getAdditional();
            },
            (err:any)=>{
              this._snackBar.openSnackBar('Error al borrar Adicional', 'OK');
              this.isLoading = false;
            }
          )
        }
      });
  }

  edit(additional:Additional): void {
    this.dialog.open(CreateAdditionalComponent, {
      width: '600px',
      data: {
        additional,
        edit: true
      }
    })
  }

  detail(additional:Additional): void {
    this.dialog.open(CreateAdditionalComponent, {
      width: '600px',
      data: {
        additional,
        detail: true
      }
    })
  }


  ngOnDestroy(): void {
    Object.values(this.subscriptions).forEach(value => value.unsubscribe());
  }
}
